<template>
  <div class="language-selection" :title="$t('language.title')">
    <div
      class="flag-cont"
      :class="{ flagInactive: mappedLang != lang }"
      v-for="(lang, index) in languages"
      :key="index"
      @click="changeLanguage(lang)"
    >
      <span
        v-if="mappedLang == lang"
        class="flag-icon"
        :class="currentFlag"
      ></span>
      <div v-else>
        <span class="flag-icon" :class="flag(lang)"></span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "LanguageSelection",
  data() {
    return {
      languageMapping: {
        de: "de",
        ch: "ch",
        gb: "gb",
        en: "gb",
        "en-GB": "gb",
        "en-US": "gb",
      },
      languages: ["de", "ch", "gb"],
      language: localStorage.language,
    };
  },
  computed: {
    currentFlag() {
      return "flag-icon-" + this.mappedLang;
    },
    mappedLang() {
      return this.languageMapping[this.language];
    },
    ...mapGetters({
      api_lang: "api_lang",
    }),
  },
  watch: {
    "$i18n.locale": {
      handler(val) {
        this.language = val;
      },
      deep: true,
    },
  },
  methods: {
    changeLanguage(language) {
      localStorage.language = language;
      this.$i18n.locale = language;
      this.language = language;
    },
    flag(lang) {
      return "flag-icon-" + lang;
    },
  },
};
</script>
<style lang="scss">
.language-selection {
  .flag-cont {
    display: inline-block;
    cursor: pointer;
    font-size: 1.2em;
    margin: 0 3px;
  }
  .flagInactive {
    font-size: 0.7em;
  }
}
</style>
