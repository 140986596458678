import Message from "@/store/messages";
import api from "@/store/api.js";
import asyncPagination from "@/store/asyncPagination";
import { required, requiredIf } from "vuelidate/lib/validators";
import { toQuery } from "../../mixins";

const takings = {
  namespaced: true,
  modules: {
    pg: asyncPagination,
  },
  state: () => ({
    list: [],
    eventList: [
      {
        title: "taking.event.anon.title",
        subtitle: "taking.event.anon.subtitle",
        label: "taking.event.anon.label",
        value: "no-reply",
      },
      {
        title: "taking.event.crew.title",
        subtitle: "taking.event.crew.subtitle",
        label: "taking.event.crew.label",
        value: "crew",
      },
      {
        title: "taking.event.self.title",
        subtitle: "taking.event.self.subtitle",
        label: "taking.event.self.label",
        value: "self",
      },
    ],
    sources: [
      {
        title: "taking.sources.unknown.title",
        subtitle: "taking.sources.donation",
        label: "taking.sources.unknown.title",
        money: { amount: 0, currency: "EUR" },
        value: "unknown",
        norms: "donation",
      },
      {
        title: "taking.sources.can.title",
        subtitle: "taking.sources.donation",
        label: "taking.sources.can.title",
        money: { amount: 0, currency: "EUR" },
        value: "can",
        norms: "donation",
      },
      {
        title: "taking.sources.box.title",
        subtitle: "taking.sources.donation",
        label: "taking.sources.box.title",
        money: { amount: 0, currency: "EUR" },
        value: "box",
        norms: "donation",
      },
      {
        title: "taking.sources.gl.title",
        subtitle: "taking.sources.donation",
        label: "taking.sources.gl.title",
        money: { amount: 0, currency: "EUR" },
        value: "gl",
        norms: "donation",
      },
      {
        title: "taking.sources.other.title",
        subtitle: "taking.sources.donation",
        label: "taking.sources.other.title",
        money: { amount: 0, currency: "EUR" },
        value: "other",
        norms: "donation",
      },
      {
        title: "taking.sources.merch.title",
        subtitle: "taking.sources.economic",
        label: "taking.sources.merch.title",
        money: { amount: 0, currency: "EUR" },
        value: "merch",
        norms: "economic",
      },
      {
        title: "taking.sources.other_ec.title",
        subtitle: "taking.sources.economic",
        label: "taking.sources.other_ec.title",
        money: { amount: 0, currency: "EUR" },
        value: "other_ec",
        norms: "economic",
      },
    ],
    create: {
      name: "",
      crew_id: null,
      event_id: "",
      event: {},
      external: {},
      sources: [],
      comment: "",
      date_of_taking: 0,
    },
    edit: {
      id: "",
      crew_id: "",
      external: {},
      sources: [],
      comment: "",
    },
    backup: {},
    query: {
      status_open: true,
      status_confirmed: true,
      status_wait: true,
      status_none: true,
      event_type: [],
    },
    current: null,
    current_id: "",
    editValue: {},
  }),
  mutations: {
    create(state, value) {
      state.create = value;
    },
    clean_create(state) {
      state.create = {
        name: "",
        crew_id: null,
        event_id: "",
        event: {},
        external: {},
        sources: [],
        comment: "",
      };
    },
    current(state, value) {
      state.current = value;
      state.editValue = value;
    },
    list(state, value) {
      state.list = value;
    },
    editValue(state, value) {
      state.editValue = value;
    },
    update(state, value) {
      state.update = value;
    },
    edit(state, value) {
      state.edit = value;
    },
    reset_query(state) {
      state.query = {};
    },
    start_edit(state) {
      state.backup = state.edit;
    },
    close_edit(state) {
      state.edit = state.backup;
    },
    query(state, value) {
      state.query = value;
      if ("crew_id" in value) {
        if (!Array.isArray(value.crew_id)) {
          state.query.crew_id = [value.crew_id];
        }
      }
      if ("status_confirmed" in value) {
        state.query.status_confirmed = value.status_confirmed === "true";
      }
      if ("status_open" in value) {
        state.query.status_open = value.status_open === "true";
      }
      if ("status_wait" in value) {
        state.query.status_wait = value.status_wait === "true";
      }
      if ("status_none" in value) {
        state.query.status_none = value.status_none === "true";
      }
    },
  },
  getters: {
    query(state) {
      return state.query;
    },
    list(state) {
      return state.list;
    },
    current(state) {
      return state.current;
    },
    source_validations() {
      return {
        value: {
          sources: {
            $each: {
              money: {
                amount: {
                  minValue(val) {
                    return val > 0;
                  },
                },
              },
            },
          },
        },
      };
    },
    validations(state, getters, rootState) {
      let validation = {
        name: {
          required,
        },
        date_of_taking: {
          minValue: function (val) {
            return val > 0;
          },
        },
        crew_id: {
          required: requiredIf(function () {
            return rootState.user.roles.system.length < 2;
          }),
        },
        /*sources: {
          required,
        },*/
      };

      let createVal = {
        external: {
          asp: {
            required: requiredIf(function () {
              return (
                state.create.sources.find(
                  (el) => el.has_external && el.has_external == true
                ) != undefined
              );
            }),
          },
        },
      };

      let currentVal = {
        external: {
          asp: {
            required: requiredIf(function () {
              return (
                state.current.sources.find(
                  (el) => el.has_external && el.has_external == true
                ) != undefined
              );
            }),
          },
        },
      };

      let editVal = {
        external: {
          asp: {
            required: requiredIf(function () {
              return (
                state.current &&
                state.current.sources.find(
                  (el) => el.has_external && el.has_external == true
                ) != undefined
              );
            }),
          },
        },
      };
      let valueVal = state.current == null ? createVal : currentVal;

      let money = {
        amount: {
          required,
        },
      };
      return {
        create: { ...validation, ...createVal },
        value: { ...validation, ...valueVal },
        edit: { ...validation, ...editVal },
        current: { ...validation, ...currentVal },
        money,
      };
    },
  },
  actions: {
    async add({ dispatch }) {
      await dispatch({ type: "create" });
      await dispatch({ type: "list" });
    },
    async update({ dispatch }) {
      await dispatch({ type: "updateReq" });
      await dispatch({ type: "list" });
    },
    create({ commit, state }) {
      state.create.sources.map((element) => {
        element.money.amount = parseInt(element.money.amount);
      });
      if (state.create.sources.length > 0) {
        state.create.currency = state.create.sources[0].currency;
      }
      state.create.new_sources = state.create.sources;
      return new Promise((resolve, reject) => {
        api
          .post("/finances/taking", state.create)
          .then((response) => {
            commit("clean_create");
            commit("current", response.data.payload);
            commit(
              "currentMsg",
              Message.getMessage("success", "taking.created"),
              { root: true }
            );
            resolve();
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
    updateReq({ commit, state }) {
      state.edit.sources.map((element) => {
        element.money.amount = parseInt(element.money.amount);
      });
      return new Promise((resolve, reject) => {
        api
          .put("/finances/taking", state.edit)
          .then((response) => {
            commit("current", response.data.payload);
            commit(
              "currentMsg",
              Message.getMessage("success", "taking.updated"),
              { root: true }
            );
            resolve();
          })
          .catch((error) => {
            if (error.response.data.message === "event_failure") {
              commit(
                "currentMsg",
                Message.getMessage("error", "error.deposit_confirmed_failure"),
                { root: true }
              );
            } else {
              commit(
                "currentMsg",
                Message.getMessage("error", "error.unknown"),
                {
                  root: true,
                }
              );
            }
            reject(error);
          });
      });
    },
    list({ commit, state }) {
      var obj = state.query;
      const query = toQuery(obj);
      return new Promise((resolve, reject) => {
        api
          .get("/finances/taking" + query)
          .then((response) => {
            commit(
              "pg/pageSize",
              response.data.payload === null ? 1 : response.data.payload.length
            );
            commit(
              "pg/listLength",
              response.data.payload === null ? 1 : response.data.payload.length
            );
            commit("list", response.data.payload);
            resolve(response.data.payload);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getByID({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .get("/finances/taking/" + data.data)
          .then((response) => {
            commit("edit", response.data.payload), resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteByID({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .delete("/finances/taking/" + data.data)
          .then((response) => {
            if (response.status === 200) {
              commit(
                "currentMsg",
                Message.getMessage("success", "taking.deleted"),
                { root: true }
              );
            }
            resolve();
          })
          .catch((error) => {
            commit(
              "currentMsg",
              Message.getMessage(
                "error",
                "taking." + error.response.data.message
              ),
              { root: true }
            );
            reject(error);
          });
      });
    },
  },
};
export default takings;
