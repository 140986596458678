import api from "@/store/api";
import asyncPagination from "@/store/asyncPagination";
import Message from "@/store/messages";
import { cleanQuery } from "../asyncPagination";
import active from "./active";
import roles from "./roles";
import roles_history from "./roles_history";

const users = {
  namespaced: true,
  modules: {
    pg: asyncPagination,
    active: active,
    roles: roles,
    roles_history: roles_history,
  },
  state: () => ({
    current: null,
    list: [],
  }),
  mutations: {
    current(state, value) {
      state.current = value;
      state.active.current.user_id = value.id;
      state.roles.current.user_id = value.id;
    },
    list(state, value) {
      state.list = value;
    },
    clean_current(state) {
      state.current = null;
      state.active.current.user_id = null;
      state.roles.current.user_id = null;
    },
  },
  getters: {
    current(state) {
      return state.current;
    },
    list(state) {
      return state.list;
    },
    userHasPoolPermission: (state) => (permission) => {
      if (!permission) {
        return (
          state.current.pool_roles != null &&
          state.current.pool_roles.length > 0
        );
      } else if (!Array.isArray(permission)) {
        return (
          state.current.pool_roles != null &&
          state.current.pool_roles.find((el) => el.name == permission)
        );
      } else {
        return (
          state.current.pool_roles != null &&
          state.current.pool_roles.find((el) => permission.includes(el.name))
        );
      }
    },
    userHasSystemPermission: (state) => (permission) => {
      if (
        !(
          state.current &&
          state.current.system_roles &&
          state.current.system_roles.length > 1
        )
      ) {
        return false;
      }
      if (!permission) {
        return (
          state.current.system_roles != null &&
          state.current.system_roles.length > 1
        );
      } else if (!Array.isArray(permission)) {
        return (
          state.current.system_roles != null &&
          state.current.system_roles.find((el) => el.name == permission) !=
            undefined
        );
      } else {
        return (
          state.current.system_roles != null &&
          state.current.system_roles.find(
            (el) => permission.find((p) => el.name == p) != undefined
          ) != undefined
        );
      }
    },
  },
  actions: {
    async delete({ dispatch }) {
      await dispatch({ type: "deleteReq" });
      await dispatch({ type: "list" });
    },
    list({ commit }) {
      return new Promise((resolve, reject) => {
        api
          .get("/users")
          .then((response) => {
            commit("list", response.data.payload);
            commit("pg/pageSize", response.data.payload.length);
            resolve(response.data.payload);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    crew({ commit }) {
      return new Promise((resolve, reject) => {
        api
          .get("/users/crew")
          .then((response) => {
            if (response.data.payload) {
              commit("list", response.data.payload);
              commit("pg/pageSize", response.data.payload.length);
              resolve(response.data.payload);
            } else {
              resolve();
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    publicCrew({ commit }) {
      return new Promise((resolve, reject) => {
        api
          .get("/users/crew/public")
          .then((response) => {
            commit("list", response.data.payload);
            resolve(response.data.payload);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteReq({ commit, state }) {
      return new Promise((resolve, reject) => {
        api
          .delete("/users/" + state.current.id)
          .then((response) => {
            if (response.status / 100 === 2) {
              commit(
                "currentMsg",
                Message.getMessage("success", "user.deleted"),
                {
                  root: true,
                }
              );
              resolve(response.data.payload);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getById({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .get("/users/" + data.data)
          .then((response) => {
            commit("current", response.data.payload);
            resolve(response.data.payload);
          })
          .catch((error) => {
            if (error.response.status != 404) {
              commit(
                "currentMsg",
                Message.getMessage("error", "error.unknown"),
                {
                  root: true,
                }
              );
            }
            reject(error);
          });
      });
    },
    sync({ commit, state }) {
      return new Promise((resolve, reject) => {
        api
          .get("/users/profile/sync/" + state.current.profile.id)
          .then(() => {
            commit("currentMsg", Message.getMessage("success", "user.synced"), {
              root: true,
            });
            resolve();
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
    update_profile({ commit, state }) {
      return new Promise((resolve, reject) => {
        api
          .put("/users/profile/update", state.current.profile)
          .then(() => {
            commit(
              "currentMsg",
              Message.getMessage("success", "user.updated"),
              {
                root: true,
              }
            );
            resolve();
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
    update_organisation({ commit, state }) {
      return new Promise((resolve, reject) => {
        api
          .put("/users/organisation", state.current)
          .then(() => {
            commit(
              "currentMsg",
              Message.getMessage("success", "user.updated"),
              {
                root: true,
              }
            );
            resolve();
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
    create_crew({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        const request = {
          crew_id: data.data.crew_id,
          user_id: state.current.id,
        };
        api
          .post("/users/crew/create", request)
          .then((response) => {
            commit(
              "currentMsg",
              Message.getMessage("success", "users_crew.updated"),
              {
                root: true,
              }
            );
            resolve(response.data.payload);
          })
          .catch((error) => {
            if (error.response.status == 404) {
              commit(
                "currentMsg",
                Message.getMessage("warn", "users_crew.not_found"),
                { root: true }
              );
            } else {
              commit(
                "currentMsg",
                Message.getMessage("error", "error.unknown"),
                {
                  root: true,
                }
              );
            }
            reject(error);
          });
      });
    },
    update_crew({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        const request = {
          crew_id: data.data.crew_id,
          user_id: state.current.id,
        };
        api
          .put("/users/crew/update", request)
          .then((response) => {
            commit(
              "currentMsg",
              Message.getMessage("success", "users_crew.updated"),
              {
                root: true,
              }
            );
            resolve(response.data.payload);
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
    delete_crew({ commit, state }) {
      return new Promise((resolve, reject) => {
        api
          .delete("/users/crew/" + state.current.id)
          .then((response) => {
            commit(
              "currentMsg",
              Message.getMessage("success", "users_crew.deleted"),
              {
                root: true,
              }
            );
            resolve(response.data.payload);
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
    create_address({ commit, state }) {
      return new Promise((resolve, reject) => {
        api
          .post("/users/address/create", {
            ...state.current.address,
            ...{ user_id: state.current.id },
          })
          .then((response) => {
            commit(
              "currentMsg",
              Message.getMessage("success", "users_address.updated"),
              { root: true }
            );
            resolve(response.data.payload);
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
    update_address({ commit, state }) {
      return new Promise((resolve, reject) => {
        api
          .put("/users/address/update", state.current.address)
          .then((response) => {
            commit(
              "currentMsg",
              Message.getMessage("success", "users_address.updated"),
              { root: true }
            );
            resolve(response.data.payload);
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
    delete_address({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .delete("/users/address/delete/" + data.data)
          .then((respnse) => {
            commit(
              "currentMsg",
              Message.getMessage("success", "users_address.deleted"),
              { root: true }
            );
            resolve(respnse.data.payload);
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
    queryList(_, data) {
      const params = cleanQuery(data.data);

      return new Promise((resolve, reject) => {
        api
          .get("/users?" + params)
          .then((response) => {
            resolve(response.data.payload);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
export default users;
