import api from "@/store/api.js";
import Message from "@/store/messages";
import pagination from "@/store/pagination";
import { cleanQuery } from "../asyncPagination";

const participations = {
  namespaced: true,
  modules: {
    pg: pagination,
  },
  state: () => ({
    create: {
      event_id: null,
      user_id: null,
      comment: null,
    },
    list: null,
    current: null,
  }),
  mutations: {
    create(state, value) {
      state.create = value;
    },
    state(state, value) {
      state.current.status = value;
    },
    current(state, value) {
      state.current = value;
    },
    list(state, value) {
      state.list = value;
    },
  },
  getters: {
    create(state) {
      return state.create;
    },
    list(state) {
      return state.list;
    },
    current(state) {
      return state.current;
    },
    has(state) {
      if (state.current.id == "") {
        return false;
      }
      return true;
    },
  },
  actions: {
    async reject({ dispatch, commit }) {
      commit("state", "rejected");
      await dispatch({ type: "update" });
    },
    async accept({ dispatch, commit }) {
      commit("state", "confirmed");
      await dispatch({ type: "update" });
    },
    async withdraw({ dispatch, commit }) {
      commit("state", "withdrawn");
      await dispatch({ type: "update" });
    },
    async reapply({ dispatch, commit }) {
      commit("state", "requested");
      await dispatch({ type: "update" });
    },
    apply({ commit, state }) {
      return new Promise((resolve, reject) => {
        api
          .post("/events/participation", state.create)
          .then((response) => {
            commit("current", null);
            commit("create", {
              event_id: null,
              user_id: null,
              comment: null,
            });
            commit(
              "currentMsg",
              Message.getMessage("success", "participation.created"),
              { root: true }
            );
            resolve(response.data.payload);
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
    update({ commit, state }, data) {
      return new Promise((resolve, reject) => {
        api
          .put("/events/participation", data.data ? data.data : state.current)
          .then((response) => {
            commit("current", null);
            commit(
              "currentMsg",
              Message.getMessage("success", "participation.updated"),
              { root: true }
            );
            resolve(response.data.payload);
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
    get({ commit, state }) {
      return new Promise((resolve, reject) => {
        api
          .get("/events/participation?event_id=" + state.current.event_id)
          .then((response) => {
            commit("current", response.data.payload);
            resolve();
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
    list({ commit }) {
      return new Promise((resolve, reject) => {
        api
          .get("/events/participation")
          .then((response) => {
            commit("list", response.data.payload);
            commit("dropdown", response.data.payload);
            resolve(response.data.payload);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    listUser({ commit }) {
      return new Promise((resolve, reject) => {
        api
          .get("/events/participation/user")
          .then((response) => {
            commit("list", response.data.payload);
            resolve(response.data.payload);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getUser({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .get("/events/participation/user/" + data.data)
          .then((response) => {
            commit("list", response.data.payload);
            resolve(response.data.payload);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    listEvent({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .get("/events/participation/event/" + data.data)
          .then((response) => {
            commit("list", response.data.payload);
            resolve(response.data.payload);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    delete({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .delete("/events/participation/" + data.data)
          .then((respnse) => {
            commit(
              "currentMsg",
              Message.getMessage("success", "participation.deleted"),
              { root: true }
            );
            resolve(respnse.data.payload);
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
    queryList(_, data) {
      const params = cleanQuery(data.data);

      return new Promise((resolve, reject) => {
        api
          .get("/events/participation?" + params)
          .then((response) => {
            resolve(response.data.payload);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
export default participations;
