import api from "@/store/api.js";
import Message from "@/store/messages";
import pagination from "@/store/pagination";
import { email, required } from "vuelidate/lib/validators";

const organisations = {
  namespaced: true,
  modules: {
    pg: pagination,
  },
  state: () => ({
    list: [],
    dropdown: [],
    current: null,
    doCreate: false,
    create: {
      name: "",
      email: "",
      abbreviation: "",
    },
  }),
  mutations: {
    create(state, value) {
      state.create = value;
    },
    clean_current(state) {
      state.current = null;
    },
    doCreate(state, value) {
      state.doCreate = value;
    },
    current(state, value) {
      state.current = value;
    },
    list(state, value) {
      state.list = value;
    },
    dropdown(state, value) {
      var selectList = [];
      value
        ? value.forEach((row, index) => {
            selectList[index] = {
              title: row["name"],
              label: row["name"],
              value: row["id"],
            };
          })
        : null;
      state.dropdown = selectList;
    },
  },
  getters: {
    list(state) {
      return state.list;
    },
    current(state) {
      return state.current;
    },
    dropdown(state) {
      return state.dropdown;
    },
    validations() {
      return {
        value: {
          name: { required },
          email: { required, email },
          abbreviation: { required },
        },
      };
    },
  },
  actions: {
    async create({ dispatch }) {
      await dispatch({ type: "createReq" });
      await dispatch({ type: "list" });
    },
    async update({ dispatch }) {
      await dispatch({ type: "updateReq" });
      await dispatch({ type: "list" });
    },
    async delete({ dispatch }, data) {
      await dispatch({ type: "deleteReq", data: data.data });
      await dispatch({ type: "list" });
    },
    createReq({ commit, state }) {
      return new Promise((resolve, reject) => {
        api
          .post("/organisations", state.create)
          .then((response) => {
            commit("current", null);
            commit(
              "currentMsg",
              Message.getMessage("success", "organisation.created"),
              { root: true }
            );
            resolve(response.data.payload);
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
    updateReq({ commit, state }) {
      return new Promise((resolve, reject) => {
        api
          .put("/organisations", state.current)
          .then((response) => {
            commit("current", null);
            commit(
              "currentMsg",
              Message.getMessage("success", "organisation.updated"),
              { root: true }
            );
            resolve(response.data.payload);
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
    deleteReq({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .delete("/organisations/" + data.data)
          .then((response) => {
            commit("current", null);
            commit(
              "currentMsg",
              Message.getMessage("success", "organisation.deleted"),
              { root: true }
            );
            resolve(response.data.payload);
          })
          .catch((error) => {
            commit("currentMsg", Message.getMessage("error", "error.unknown"), {
              root: true,
            });
            reject(error);
          });
      });
    },
    list({ commit }) {
      return new Promise((resolve, reject) => {
        api
          .get("/organisations")
          .then((response) => {
            commit("list", response.data.payload ? response.data.payload : []);
            commit("dropdown", response.data.payload);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getByID({ commit }, data) {
      return new Promise((resolve, reject) => {
        api
          .get("/organisations/" + data.data)
          .then((response) => {
            commit("current", response.data.payload), resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
export default organisations;
